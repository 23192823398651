<template>
  <UserList search-type="7" />
</template>

<script>
import UserList from '@/components/UserList'

export default {
  name: 'VideoCallUsers',
  components: {
    UserList,
  }
}
</script>
